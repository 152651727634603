import { render, staticRenderFns } from "./AdminMobileBlock.vue?vue&type=template&id=2238bc95&scoped=true"
import script from "./AdminMobileBlock.vue?vue&type=script&lang=js"
export * from "./AdminMobileBlock.vue?vue&type=script&lang=js"
import style0 from "./AdminMobileBlock.vue?vue&type=style&index=0&id=2238bc95&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../node_modules/.pnpm/vue-loader@15.11.1_css-loader@6.11.0_lodash@4.17.21_prettier@2.4.1_react-dom@17.0.2_react@17._bjcfza3jsc7olvazfolizmjumy/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2238bc95",
  null
  
)

export default component.exports